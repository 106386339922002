import { API_PATH } from 'components/routing/constants/api';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { generateLocaleFromUserLanguage } from 'components/routing/utils/localeUtils';
import { ClientEntity } from 'entityTypes/Client';
import { UserClientEntity } from 'entityTypes/UserClient';
import { HttpMethod } from 'enums/httpMethodEnum';
import { UserLanguageCodeEnum } from 'enums/UserLanguageCodeEnum';
import { IDeletedResponse } from 'interfaces';
import { i18n, TFunction } from 'next-i18next';
import Router from 'next/router';
import { selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { adminClientsState } from 'recoil/atoms/adminAtoms';
import { userModelState } from 'recoil/atoms/userAtoms';
import { deleteDocumentByTagRequest } from 'recoil/selectors/documentsSelector';
import { updateUserActiveClientRequest } from 'recoil/selectors/usersSelectors';
import {
  CreateClientDto,
  IUserClientsWithUsersDeletedUuids,
  UpdateClientWithAssignedUsersDto,
} from 'types/client';
import { CLIENT_LOGO_CLOUDINARY_TAG, generateCloudinaryTag } from 'utils/cloudinaryHelper';
import { getUserClientByActiveClientUuid } from 'utils/userHelper';
import { v4 as uuid } from 'uuid';

import { TBasePaths } from 'constants/translations';
import { request } from '../../../pages/api/fetchService';
import Snackbar from '../../components/shared/Toaster/ToasterWithoutState';
import { getURLByEndpoint } from './SelectorsHelper';

export const CLIENT_SUCCESS_MESSAGES = {
  CREATED: `${TBasePaths.PA_COMMON_WORD}.clientSuccessfullyCreated`,
  UPDATED: `${TBasePaths.PA_COMMON_WORD}.clientSuccessfullyUpdated`,
  DELETED: `${TBasePaths.PA_COMMON_WORD}.clientSuccessfullyDeleted`,
};

export const CLIENT_WARNING_MESSAGES = {
  NOT_FOUND: `${TBasePaths.PA_COMMON_WORD}.clientNotFound`,
};

export const getClientsSelector = selector({
  key: 'GetClientsSelector',
  get: async (): Promise<ClientEntity[]> => {
    const res = await request<ClientEntity[]>(
      getURLByEndpoint(API_PATH.client.getClientsWithUserClients),
      HttpMethod.GET
    );
    setRecoil(adminClientsState, res);
    return res;
  },
});

export function findUserClientByClientUuid(
  clientUuid: string,
  accessToken?: string
): Promise<UserClientEntity> {
  return request<UserClientEntity>(
    `${getURLByEndpoint(API_PATH.userClient.findOneByClient)}/${clientUuid}`,
    HttpMethod.GET,
    undefined,
    accessToken
  );
}

export const createClientRequest = async (dto: CreateClientDto): Promise<ClientEntity> => {
  const res = await request<ClientEntity>(
    getURLByEndpoint(API_PATH.client.createOne),
    HttpMethod.POST,
    dto
  );
  Snackbar.success(i18n!.t(CLIENT_SUCCESS_MESSAGES.CREATED));
  return res;
};

export const updateClientWithAssignedUsersRequest = async (
  clientUuid: string,
  dto: UpdateClientWithAssignedUsersDto
): Promise<ClientEntity> => {
  const res = await request<ClientEntity>(
    getURLByEndpoint(API_PATH.client.updateOne, clientUuid),
    HttpMethod.PATCH,
    {
      ...dto,
      userClientsDeletedUuids:
        dto.userClientsWithUsersDeletedUuids?.map((uc) => uc.userClientDeletedUuid ?? undefined) ??
        [],
    }
  );
  Snackbar.success(i18n!.t(CLIENT_SUCCESS_MESSAGES.UPDATED));
  return res;
};

export const deleteClientWithAssignedUsersRequest = async (
  clientUuid: string
): Promise<IDeletedResponse<ClientEntity>> => {
  const res = await request<IDeletedResponse<ClientEntity>>(
    getURLByEndpoint(API_PATH.client.deleteOne, clientUuid),
    HttpMethod.DELETE
  );
  Snackbar.success(i18n!.t(CLIENT_SUCCESS_MESSAGES.DELETED));
  return res;
};

export const createClientSelector = selector({
  key: 'CreateClientSelector',
  get: ({ getCallback, get }) => {
    const adminClients = get(adminClientsState);
    return getCallback(() => async (dto: CreateClientDto) => {
      const clientCurrency = {
        exchangeReportDate: String(new Date()),
        isBase: true,
        exchangeRate: 1,
        clientUuid: dto.clientUuid,
        currencyCode: dto.baseCurrencyCode as string,
        clientCurrencyUuid: uuid(),
        currency: { currencyCode: dto.baseCurrencyCode as string },
      };
      const createdClient = await createClientRequest({
        ...dto,
        baseCurrencyCode: undefined,
        clientCurrency,
      });
      setRecoil(adminClientsState, [
        ...adminClients,
        { ...createdClient, clientCurrencies: [clientCurrency] },
      ]);
      return createdClient;
    });
  },
});

export const updateClientWithAssignedUsersSelector = selector({
  key: 'UpdateClientWithAssignedUsersSelector',
  get: ({ getCallback, get }) => {
    const user = get(userModelState);
    const adminClients = get(adminClientsState);
    const updateClient = async (clientUuid: string, dto: UpdateClientWithAssignedUsersDto) => {
      const { userClients, ...updatedClient } = await updateClientWithAssignedUsersRequest(
        clientUuid,
        dto
      );

      const index = adminClients.findIndex((ac) => ac.clientUuid === updatedClient.clientUuid);
      if (index === -1) {
        Snackbar.warning(i18n!.t(CLIENT_WARNING_MESSAGES.NOT_FOUND));
        return;
      } else {
        const updatedAdminClients = [...adminClients];
        updatedAdminClients[index] = { ...updatedClient, userClients };
        setRecoil(adminClientsState, updatedAdminClients);
      }
      return updatedClient;
    };

    const updateSelectedUserClient = (
      updatedClient: ClientEntity,
      newUserClients: UserClientEntity[] | []
    ) => {
      if (!newUserClients.length) return undefined;

      //Case if current user was deleted from assigned users
      const hasUserClientsChanged = newUserClients?.length !== user.userClients?.length;

      if (hasUserClientsChanged) {
        const newCurrentUserClient =
          getUserClientByActiveClientUuid(newUserClients, user.activeClientUuid) ||
          newUserClients[0];

        updateUserActiveClientRequest(newCurrentUserClient.client.clientUuid).then((_r) => {});
        return newCurrentUserClient;
      }

      const isSelectedClientUpdated =
        user.selectedUserClient?.client.clientUuid === updatedClient.clientUuid;

      return user.selectedUserClient
        ? {
            ...user.selectedUserClient,
            preferredCurrencyCode: isSelectedClientUpdated
              ? updatedClient.currencyCodeDefault!
              : user.selectedUserClient.preferredCurrencyCode,
            client: isSelectedClientUpdated ? updatedClient : user.selectedUserClient.client,
          }
        : undefined;
    };

    const updateUserClients = (
      updatedClient: ClientEntity,
      userClientsWithUsersDeletedUuids?: IUserClientsWithUsersDeletedUuids[]
    ) => {
      const updatedUserClients = user.userClients;

      //Case if current user was deleted from assigned users
      if (
        userClientsWithUsersDeletedUuids?.map((ucd) => ucd.userDeletedUuid).includes(user.userUuid)
      ) {
        const currentUserClientUuid = userClientsWithUsersDeletedUuids.find(
          (uc) => uc.userDeletedUuid === user.userUuid
        )?.userClientDeletedUuid;

        return updatedUserClients.filter((uc) => uc.userClientUuid !== currentUserClientUuid);
      }

      return updatedUserClients.map((uc) =>
        uc.client.clientUuid === updatedClient.clientUuid
          ? {
              ...uc,
              preferredCurrencyCode: updatedClient.currencyCodeDefault!,
              client: updatedClient,
            }
          : uc
      );
    };

    const updateSelectedUserClientAndUserClients = async (
      updatedClient: ClientEntity,
      userClientsWithUsersDeletedUuids?: IUserClientsWithUsersDeletedUuids[]
    ) => {
      const userClients = updateUserClients(updatedClient, userClientsWithUsersDeletedUuids);
      const selectedUserClient = updateSelectedUserClient(updatedClient, userClients);

      setRecoil(userModelState, (prevUser) => ({
        ...prevUser,
        activeClientUuid: selectedUserClient?.client.clientUuid || '',
        userClients,
        selectedUserClient,
      }));
      return selectedUserClient;
    };

    return getCallback(() => async (clientUuid: string, dto: UpdateClientWithAssignedUsersDto) => {
      const updatedClient = await updateClient(clientUuid, dto);
      if (updatedClient) {
        await updateSelectedUserClientAndUserClients(
          updatedClient,
          dto.userClientsWithUsersDeletedUuids
        );

        return updatedClient;
      }
    });
  },
});

export const deleteClientWithAssignedUsersSelector = selector({
  key: 'DeleteClientWithAssignedUsersSelector',
  get: ({ getCallback, get }) => {
    const user = get(userModelState);

    const deleteClient = async (clientUuid: string) => {
      const res = await deleteClientWithAssignedUsersRequest(clientUuid);
      setRecoil(adminClientsState, (prevAdminClients) =>
        prevAdminClients.filter((ac) => ac.clientUuid !== res.deletedEntity.clientUuid)
      );
      await deleteDocumentByTagRequest(
        generateCloudinaryTag(CLIENT_LOGO_CLOUDINARY_TAG, clientUuid)
      );
      return res;
    };

    const updateSelectedUserClientAndUserClients = async (deletedClientUuid: string) => {
      let selectedUserClient = user.selectedUserClient;
      let userClients = user.userClients;
      let shouldUpdateState = false;
      let shouldUpdateSelectedUserClient = false;

      if (userClients.find((uc) => uc.clientUuid === deletedClientUuid)) {
        shouldUpdateState = true;
        userClients = user.userClients.filter((uc) => uc.client.clientUuid !== deletedClientUuid);
      }

      if (selectedUserClient?.clientUuid === deletedClientUuid) {
        shouldUpdateSelectedUserClient = true;
        selectedUserClient = await findUserClientByClientUuid(userClients[0].clientUuid);
      }

      if (shouldUpdateSelectedUserClient) {
        await updateUserActiveClientRequest(selectedUserClient?.client.clientUuid || '');
      }

      if (shouldUpdateState || shouldUpdateSelectedUserClient) {
        setRecoil(userModelState, (prevUser) => ({
          ...prevUser,
          userClients,
          selectedUserClient,
          activeClientUuid: selectedUserClient?.client.clientUuid || '',
        }));
      }
    };

    return getCallback(() => async (clientUuid: string) => {
      const res = await deleteClient(clientUuid);
      await updateSelectedUserClientAndUserClients(clientUuid);
      return res;
    });
  },
});

export const updateClientSelector = selector({
  key: 'UpdateUserActiveUserClient',
  get: ({ getCallback }) => {
    return getCallback(() => async (activeClientUuid: string, t: TFunction) => {
      const userClient = await findUserClientByClientUuid(activeClientUuid);
      const clientSwitchedText = t(`${TBasePaths.PA_COMMON_WORD}.clientSwitched`);
      if (!userClient) {
        Snackbar.warning(t(CLIENT_WARNING_MESSAGES.NOT_FOUND));
        return;
      }

      updateUserActiveClientRequest(activeClientUuid).then((_r) => {});
      setRecoil(userModelState, (prevUser) => ({
        ...prevUser,
        activeClientUuid,
        selectedUserClient: userClient,
      }));

      Router.push(ROUTES_PATH_URLS.home, ROUTES_PATH_URLS.home, {
        locale: generateLocaleFromUserLanguage(
          userClient?.userLanguage || UserLanguageCodeEnum.EN_US
        ),
      }).then((_r) => {});
      Snackbar.success(`${clientSwitchedText}${userClient.client.clientName}`);
    });
  },
});

import { IAssignedUserClient } from 'components/pages/Admin/AdminUsers/AdminAssignUserClientsPanel/AdminAssignedUserClientsForm/AdminAssignUserClientsForm';
import { UserEntity } from 'entityTypes/User';
import { UserClientEntity } from 'entityTypes/UserClient';

export type UserTokenResponse = {
  msg: string;
  userModel: UserEntity;
};

export interface SaveUserPreferencesDto {
  user: Pick<UserEntity, 'userName' | 'firstName' | 'lastName' | 'email' | 'userInitial'>;
  userClient: Pick<
    UserClientEntity,
    | 'preferredCurrencyCode'
    | 'formatDate'
    | 'formatNumber'
    | 'preferredUnitMeas'
    | 'userClientUuid'
    | 'timezone'
    | 'userLanguage'
  >;
}

export interface AdditionalUserInfoResponse {
  languages: string[];
  unitsOfMeasure: string[];
  timezones: string[];
  clientStatuses: string[];
}

export enum FormatNumberEnum {
  COMMA = 'comma',
  PERIOD = 'period',
}

export interface AdminUser
  extends Pick<
    UserEntity,
    | 'userName'
    | 'userUuid'
    | 'email'
    | 'userInitial'
    | 'isFirstSignIn'
    | 'firstName'
    | 'lastName'
    | 'oktaUserId'
  > {
  userClients: UserClientEntity[] | IAssignedUserClient[] | [];
}

export interface UserClientDto
  extends Pick<
      UserClientEntity,
      | 'securityRole'
      | 'timezone'
      | 'isAdmin'
      | 'userLanguage'
      | 'primusPermission'
      | 'userClientUuid'
      | 'clientUuid'
      | 'preferredUnitMeas'
      | 'preferredCurrencyCode'
      | 'formatNumber'
      | 'formatDate'
    >,
    Pick<IAssignedUserClient, 'isNewUserClient'> {}

export interface CreateUserWithUserClientsDto
  extends Omit<AdminUser, 'clients' | 'userClients' | 'userName' | 'userUuid'> {
  userClients: UserClientDto[] | [];
}

export interface UpdateUserWithUserClientsDto
  extends CreateUserWithUserClientsDto,
    Pick<UserEntity, 'userUuid'> {
  userClientDeletedIds: string[] | [];
}

import { APP_CONFIG } from 'constants/config';

export const generateCloudinaryTag = (modelName: string, param?: string): string => {
  return `primus-${APP_CONFIG.serverEnvironmentType}-${modelName}${param ? `-${param}` : ''}`;
};

export const PROPERTY_CLOUDINARY_TAG = 'property';
export const LEASE_CLOUDINARY_TAG = 'lease';
export const CLIENT_CLOUDINARY_TAG = 'client';
export const CLIENT_LOGO_CLOUDINARY_TAG = 'client-logo';
export const PROPERTY_FILE_CLOUDINARY_TAG = 'property-file';
export const IS_PROPERTY_FILE_TAG = 'isPropertyFile';

export const LEASE_FILE_NAME_TAG = 'filename-';
export const LEASE_FILE_CATEGORY_TAG = 'category-';
export const LEASE_FILE_TYPE_TAG = 'type-';

import { useSnackbar, VariantType } from 'notistack';

let useSnackbarRef: ReturnType<typeof useSnackbar>;
export const ToasterWithoutState = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

const toaster = {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  warning(msg: string) {
    this.toast(msg, 'warning');
  },
  info(msg: string) {
    this.toast(msg, 'info');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType) {
    useSnackbarRef?.enqueueSnackbar(msg, {
      variant: variant,
      /* Reasoning behind why the duration of toaster is set this way: https://ux.stackexchange.com/a/85898 */
      autoHideDuration: Math.min(Math.max(msg.length * 50, 2000), 7000),
    });
  },
};

export default toaster;

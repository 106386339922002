import { ClientEntity } from 'entityTypes/Client';
import { atom } from 'recoil';
import { AdminUser } from 'types/user';

export const adminUsersState = atom<AdminUser[] | []>({
  key: 'AdminUsersState',
  default: [],
});

export const adminClientsState = atom<ClientEntity[]>({
  key: 'AdminClientsState',
  default: [],
});

import { PropertyEntity } from 'entityTypes/Property/Property';
import { atom, atomFamily } from 'recoil';
import { getPropertiesWithAllRelationsSelector } from 'recoil/selectors/propertySelector';
import { recoilPersist } from 'recoil-persist';
import { ICloudinaryDocument } from 'types/document';

const { persistAtom: persistPropertiesWithLeases } = recoilPersist({
  key: 'Properties.persistPropertiesWithLeases',
});

const { persistAtom: persistProperties } = recoilPersist({
  key: 'Properties.persistProperties',
});

export const propertiesWithLeasesState = atomFamily<PropertyEntity[] | undefined, string>({
  key: 'Properties.propertiesWithLeases',
  default: [],
  effects: [persistPropertiesWithLeases],
});

export const propertiesState = atomFamily<PropertyEntity, string>({
  key: 'Properties.properties',
  default: undefined,
  effects: [persistProperties],
});

export const propertiesWithAllRelationsState = atomFamily<PropertyEntity[], string>({
  key: 'PropertiesWithAllRelationsState',
  default: (activeClientUuid) => getPropertiesWithAllRelationsSelector(activeClientUuid),
});

export const propertyFileState = atom<ICloudinaryDocument | null>({
  key: 'Properties.propertyFile',
  default: null,
});

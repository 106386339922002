import { Environment, Network, RecordSource, Store } from 'relay-runtime';

const network = Network.create((operation, variables) => {
  const graphQLURL = undefined;
  return fetch(
    graphQLURL ? graphQLURL : 'https://dev.avant.avisonyoung.com/api/properties/graphql',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    }
  ).then((response) => {
    return response.json();
  });
});
const source = new RecordSource();
const store = new Store(source);

const environment = new Environment({
  network,
  store,
});

export default environment;

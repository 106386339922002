import {
  Box,
  Button,
  Container as MuiContainer,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import colors from './colors';
import React from 'react';

//https://mui.com/system/styled/#what-problems-does-it-solve
const muiReservedPropNames = ['ownerState', 'theme', 'sx', 'as'];
export const withTransientMuiProps = {
  shouldForwardProp: (propName: string) =>
    !propName.startsWith('$') && !muiReservedPropNames.includes(propName),
};

//Replacement for https://react.dev/blog/2024/04/25/react-19-upgrade-guide#removed-proptypes-and-defaultprops
export const withDefaultProps = <
  T extends React.ComponentType<{ children: NonNullable<React.ReactNode> }>,
>(
  component: T,
  defaultProps: Partial<React.ComponentProps<T>>
) => {
  const wrappedComponent = (props: React.ComponentProps<T>) =>
    React.createElement(component, { ...props, ...defaultProps });
  return wrappedComponent;
};

export const Container = styled(MuiContainer)({
  display: 'flex',
  flexDirection: 'column',
  padding: '2.6rem 2.2rem 2.8rem 3.2rem',
  minHeight: 'calc(100vh - 6.4rem)',
  height: '100%',
});

export const ActivityLogContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2.6rem 2.2rem 2.8rem 3.2rem',
  height: 'calc(100% - 52px)',
  width: '100%',
  overflow: 'auto',
}));

export const PrimaryButton = styled(withDefaultProps(Button, { size: 'small' }))(() => ({
  boxShadow: 'none',
  height: 'fit-content',
  backgroundColor: colors.amethystMain,
  color: colors.white,
  padding: '0.6rem 1.2rem 0.6rem 0.8rem',

  textTransform: 'none',
  '&:hover': {
    backgroundColor: colors.black,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: colors.lightGrey,
    boxShadow: 'none',
  },
}));

export const GenericButton = styled(Button)(() => ({
  boxShadow: 'none',
  padding: '0.6rem 1.2rem 0.6rem 0.8rem',
  textTransform: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
}));

export const PrimaryIconButton = styled(IconButton)(() => ({
  color: colors.amethystMain,
  transition:
    'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: colors.amethystMain,
    color: colors.white,
  },
}));

export const HorizontalStack = styled(Stack)(({ theme }) => ({
  gap: theme?.spacing(1),
  flexDirection: 'row',
  alignItems: 'center',
}));

export const SwitchLabel = styled(
  withDefaultProps(Typography, { variant: 'regularParagraph' }),
  withTransientMuiProps
)<{ $color?: string }>(({ $color }) => ({
  textWrap: 'nowrap',
  alignContent: 'center',
  color: $color,
}));

import { getRecoil } from 'recoil-nexus';

import { HttpMethod } from '../../src/enums/httpMethodEnum';
import { userModelState } from '../../src/recoil/atoms/userAtoms';
import {
  CURRENT_SERVER_ENVIRONMENT_TYPE,
  EnvironmentTypeEnum,
} from '../../src/recoil/selectors/SelectorsHelper';
import { handleErrors } from './errorHandling';

const setHeaders = (body?: unknown, token?: string) => {
  const requestHeaders: HeadersInit = new Headers();

  if (token) {
    requestHeaders.set('authorization', `Bearer ${token}`);
  }

  const userModel = getRecoil(userModelState);
  if (userModel?.accessToken) {
    if (!token) {
      requestHeaders.set('authorization', `Bearer ${userModel.accessToken}`);
    }
    requestHeaders.set('clientid', <string>userModel.selectedUserClient?.clientUuid);
  }

  if (body) {
    requestHeaders.set('Content-Type', 'application/json');
  }

  return requestHeaders;
};

export async function request<T>(
  url: string,
  method: HttpMethod,
  body?: unknown,
  token?: string
): Promise<T> {
  const requestHeaders = setHeaders(body, token);
  const isOffline = CURRENT_SERVER_ENVIRONMENT_TYPE === EnvironmentTypeEnum.OFFLINE;
  const reqMethod = isOffline ? HttpMethod.GET : method;
  const reqBody = isOffline ? undefined : JSON.stringify(body);

  return fetch(url, {
    headers: requestHeaders,
    method: reqMethod,
    body: reqBody,
  })
    .then(async (response) => {
      if (response.ok) {
        let result: T;
        const contentType = response.headers.get('Content-Type');
        if (
          contentType?.includes('application/pdf') ||
          contentType?.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        ) {
          result = (await response.blob()) as unknown as T;
        } else {
          result = await response.json();
        }
        return result; // Return the result if the response is OK.
      } else {
        const result = await response.json();
        const responseError = {
          type: 'Error',
          message: result.message || 'Something went wrong',
          error: result.error,
          data: result.data || '',
          code: result.statusCode || '',
        };
        let error = new Error();
        error = { ...error, ...responseError };
        throw error; // Throw an error for non-OK responses.
      }
    })
    .catch((err) => {
      handleErrors(err);
      let error = new Error();
      const responseError = {
        type: 'Error',
        message: err.message || 'Something went wrong',
        error: err.error,
        data: err.data || '',
        code: err.statusCode || err.code || '',
      };
      error = { ...error, ...responseError };
      throw error; // Rethrow the error so that it can be caught by the caller.
    });
}

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter';
import { LicenseInfo } from '@mui/x-license-pro';
import { AuthCheck } from 'components/routing/components/AuthCheck';
import ErrorBoundary from 'components/routing/components/ErrorBoundary';
import { UserClientPermissionCheck } from 'components/routing/components/UserClientPermissionCheck';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import Toaster from 'components/shared/Toaster';
import { ToasterWithoutState } from 'components/shared/Toaster/ToasterWithoutState';
import { APP_CONFIG } from 'constants/config';
import type { NextPage, NextPageContext } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import Geocode from 'react-geocode';
import { AuthProvider } from 'react-oidc-context';
import { RecoilRoot } from 'recoil';
import environment from 'recoil/graphql/createRelayEnvironment';
import RecoilNexus from 'recoil-nexus';
import { EnvironmentKey, RecoilRelayEnvironmentProvider } from 'recoil-relay';
import { muiTheme } from 'styles/theme';
import isSSR from 'utils/isSSR';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';

import MaintenanceRedirect from '../src/components/routing/components/MaintenanceRedirect';

LicenseInfo.setLicenseKey(`${APP_CONFIG.muiLicenseKey}`);
Geocode.setApiKey(APP_CONFIG.googleMapsApiKey ?? '');

const appTitle = process.env.NEXT_PUBLIC_APP_TITLE ?? 'Portfolio Management';

export const myEnvironmentKey = new EnvironmentKey(APP_CONFIG.serverEnvironmentType as string);

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout;
}

let userManager: UserManager;
if (!isSSR()) {
  const isIframe = typeof window !== 'undefined' && window.self !== window.top;
  const oidcConfig: UserManagerSettings = {
    authority: APP_CONFIG.oktaDomain as string,
    client_id: APP_CONFIG.oktaClientId as string,
    redirect_uri: (window.location.origin + ROUTES_PATH_URLS.full_okta_callback) as string,
    revokeTokensOnSignout: true,
    automaticSilentRenew: !isIframe,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
  userManager = new UserManager(oidcConfig);
}

function MyApp({ Component: PageComponent, pageProps }: Readonly<AppPropsWithLayout>) {
  const getLayout = PageComponent.getLayout ?? ((page) => page);
  const router = useRouter();
  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={muiTheme}>
        <Script
          id="PendoScript"
          dangerouslySetInnerHTML={{
            __html: `
              (function(apiKey){
                  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                  v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
              })('54aa33a6-5f42-4040-6912-a7893b24f6d9');
            `,
          }}
        />
        <AppCacheProvider {...pageProps}>
          <Head>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
            <meta name="HandheldFriendly" content="True" />
            <meta name="MobileOptimized" content="320" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>{appTitle}</title>
          </Head>
          <RecoilRoot>
            <RecoilRelayEnvironmentProvider
              environment={environment}
              environmentKey={myEnvironmentKey}
            >
              <RecoilNexus />
              <AuthProvider userManager={userManager}>
                <AuthCheck key={router.pathname}>
                  <UserClientPermissionCheck>
                    <Toaster>
                      <ToasterWithoutState />
                      <CssBaseline />
                      <MaintenanceRedirect>
                        <NuqsAdapter>{getLayout(<PageComponent {...pageProps} />)}</NuqsAdapter>
                      </MaintenanceRedirect>
                    </Toaster>
                  </UserClientPermissionCheck>
                </AuthCheck>
              </AuthProvider>
            </RecoilRelayEnvironmentProvider>
          </RecoilRoot>
        </AppCacheProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = (_ctx: NextPageContext) => {
  //Force dynamic rendering to support publicRuntimeConfig on all pages. Consider removing once another solution to get runtime env variables to client is found
  return {};
};

export default appWithTranslation(MyApp);

import { API_PATH } from 'components/routing/constants/api';
import { PropertyEntity, PropertyRelatedEntities } from 'entityTypes/Property/Property';
import { HttpMethod } from 'enums/httpMethodEnum';
import { selector, selectorFamily } from 'recoil';
import { propertiesState, propertiesWithLeasesState } from 'recoil/atoms/propertiesAtom';
import { propertyRelatedEntitiesState } from 'recoil/atoms/propertyRelatedEntitiesAtom';
import { setRecoil } from 'recoil-nexus';
import { generateCloudinaryTag, PROPERTY_CLOUDINARY_TAG } from 'utils/cloudinaryHelper';

import { request } from '../../../pages/api/fetchService';
import { deleteDocumentByTagRequest } from './documentsSelector';
import { getURLByEndpoint } from './SelectorsHelper';

export const getPropertiesWithLeasesSelector = selectorFamily({
  key: 'GetPropertiesWithLeasesSelector',
  get: (clientUuid: string) => async (): Promise<PropertyEntity[] | undefined> => {
    if (!clientUuid) return;
    const result = await request<PropertyEntity[]>(
      getURLByEndpoint(API_PATH.property.getPropertiesWithLeases, `?clientUuid=${clientUuid}`),
      HttpMethod.GET
    );
    setRecoil(propertiesWithLeasesState(clientUuid), result);
    return result;
  },
});

export const getPropertiesWithAllRelationsSelector = selectorFamily({
  key: 'GetPropertiesWithAllRelationsSelector',
  get: (clientUuid: string) => () => {
    if (!clientUuid) return [];
    return request<PropertyEntity[]>(
      getURLByEndpoint(API_PATH.property.getPropertiesWithAllRelations),
      HttpMethod.GET
    );
  },
});

export const getPropertyRelatedEntitiesSelector = selectorFamily({
  key: 'GetPropertyRelatedEntitiesSelector',
  get: (clientUuid: string) => async (): Promise<PropertyRelatedEntities | undefined> => {
    const propertyRelatedEntities: PropertyRelatedEntities = await request(
      getURLByEndpoint(API_PATH.property.getRelatedEntities),
      HttpMethod.GET
    );
    setRecoil(propertyRelatedEntitiesState(clientUuid), propertyRelatedEntities);
    return propertyRelatedEntities;
  },
});

export const getPropertyById = selectorFamily({
  key: 'GetPropertyById',
  get: (propertyUuid) => async (): Promise<PropertyEntity | undefined> => {
    if (!propertyUuid) return;
    const result = await request<PropertyEntity>(
      getURLByEndpoint(API_PATH.property.getOne, `?propertyUuid=${String(propertyUuid)}`),
      HttpMethod.GET
    );
    setRecoil(propertiesState(String(propertyUuid)), result);
    return result;
  },
});

export const createPropertySelector = selector({
  key: 'CreatePropertySelector',
  get: ({ getCallback }) => {
    return getCallback(
      () =>
        async (propertyEntity: Partial<PropertyEntity>): Promise<PropertyEntity> => {
          return request(
            getURLByEndpoint(API_PATH.property.create),
            HttpMethod.POST,
            propertyEntity
          );
        }
    );
  },
});

export const updatePropertySelector = selector({
  key: 'UpdatePropertySelector',
  get: ({ getCallback }) => {
    return getCallback(
      () =>
        (propertyEntity: Partial<PropertyEntity>): Promise<PropertyEntity | undefined | void> => {
          return request(
            getURLByEndpoint(API_PATH.property.update),
            HttpMethod.PATCH,
            propertyEntity
          );
        }
    );
  },
});

export const deletePropertySelector = selector({
  key: 'DeletePropertySelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (propertyUuid: string) => {
      await deleteDocumentByTagRequest(
        generateCloudinaryTag(PROPERTY_CLOUDINARY_TAG, propertyUuid)
      );
      return request<string>(
        getURLByEndpoint(API_PATH.property.remove, `?uuid=${propertyUuid}`),
        HttpMethod.DELETE
      );
    });
  },
});

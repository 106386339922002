import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { PermissionStatusEnum } from 'components/routing/enums/userClientPermissionEnum';
import { TBasePaths } from 'constants/translations';
import { IUserClientPermission } from 'entityTypes/UserClient';
import { v4 as uuid } from 'uuid';

export const PERMISSIONS_OPTIONS = [
  { value: PermissionStatusEnum.NONE, label: `${TBasePaths.AVANT_COMMON_WORD}.none` },
  { value: PermissionStatusEnum.VIEW, label: `${TBasePaths.AVANT_COMMON_WORD}.view` },
  {
    value: PermissionStatusEnum.VIEW_OR_EDIT,
    label: `${TBasePaths.PA_COMMON_WORD}.permissionViewOrEditLabel`,
  },
];

interface Permission {
  name: string;
  translationKey: string;
  guardedUrls?: string[];
}

export const PERMISSIONS = {
  properties: {
    name: 'Properties',
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.property_plural`,
    guardedUrls: [ROUTES_PATH_URLS.properties],
  },
  leases: {
    name: 'Leases',
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.lease_plural`,
    guardedUrls: [ROUTES_PATH_URLS.leases, ROUTES_PATH_URLS.financials],
  },
  payments: {
    name: 'Payments',
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.payment_plural`,
    guardedUrls: undefined,
  },
  invoices: {
    name: 'Invoices',
    translationKey: `${TBasePaths.PA_COMMON_WORD}.invoices`,
    guardedUrls: undefined,
  },
  contacts: {
    name: 'Contacts',
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.contacts`,
    guardedUrls: [ROUTES_PATH_URLS.contacts],
  },
  documents: {
    name: 'Documents',
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.document_plural`,
    guardedUrls: [ROUTES_PATH_URLS.documents],
  },
  leaseAccounting: {
    name: 'Lease Accounting',
    translationKey: `${TBasePaths.PA_COMMON_WORD}.leaseAccounting`,
    guardedUrls: [ROUTES_PATH_URLS.leaseAccounting],
  },
  marketIntelligence: {
    name: 'Market Intelligence',
    translationKey: `${TBasePaths.PA_COMMON_WORD}.marketIntelligence`,
    guardedUrls: [ROUTES_PATH_URLS.marketIntelligence],
  },
  transactions: {
    name: 'Transactions',
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.transaction_plural`,
    guardedUrls: [ROUTES_PATH_URLS.transactions],
  },
  notes: {
    name: 'Notes',
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.note_plural`,
    guardedUrls: [ROUTES_PATH_URLS.notes],
  },
  //Leaving these commented out since we actually did not have any permissions setup
  // home: {
  //   name: 'Home',
  //   translationKey: `${TBasePaths.AVANT_COMMON_WORD}.home`,
  //   guardedUrls: undefined,
  // },
  // criticalDates: {
  //   name: 'Critical Dates',
  //   translationKey: `${TBasePaths.PA_COMMON_WORD}.criticalDates`,
  //   guardedUrls: undefined,
  // },
  // map: {
  //   name: 'Map',
  //   translationKey: `${TBasePaths.AVANT_COMMON_WORD}.map`,
  //   guardedUrls: undefined,
  // },
  // reports: {
  //   name: 'Reports',
  //   translationKey: `${TBasePaths.PA_COMMON_WORD}.reports`,
  //   guardedUrls: undefined,
  // },
  // activityStream: {
  //   name: 'Activity Stream',
  //   translationKey: `${TBasePaths.PA_COMMON_WORD}.activityStream`,
  //   guardedUrls: undefined,
  // },
  // admin: {
  //   name: 'Admin',
  //   translationKey: `${TBasePaths.AVANT_COMMON_WORD}.admin`,
  //   guardedUrls: undefined,
  // }
};

export const NAME_TO_PERMISSION_MAP: { [key: string]: Permission } = Object.fromEntries(
  Object.entries(PERMISSIONS).map(([_, permission]) => [permission.name, permission])
);
export const PERMISSIONS_LIST: Permission[] = Object.values(PERMISSIONS);

export const INIT_USER_CLIENT_PERMISSIONS = [
  {
    uuid: uuid(),
    name: PERMISSIONS.properties.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.leases.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.payments.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.invoices.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.contacts.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.documents.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.leaseAccounting.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.marketIntelligence.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.transactions.name,
    status: null,
  },
  {
    uuid: uuid(),
    name: PERMISSIONS.notes.name,
    status: null,
  },
] as IUserClientPermission[];

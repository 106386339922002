import { API_PATH } from 'components/routing/constants/api';
import { HttpMethod } from 'enums/httpMethodEnum';
import { selector, selectorFamily } from 'recoil';
import { propertyFileState } from 'recoil/atoms/propertiesAtom';
import { setRecoil } from 'recoil-nexus';
import {
  CLIENT_LOGO_CLOUDINARY_TAG,
  generateCloudinaryTag,
  PROPERTY_FILE_CLOUDINARY_TAG,
} from 'utils/cloudinaryHelper';

import { request } from '../../../pages/api/fetchService';
import { CloudinaryResourceDeletedResponse, ICloudinaryDocument } from '../../types/document';
import { getURLByEndpoint } from './SelectorsHelper';

const getDocumentsByTagRequest = (tag: string): Promise<ICloudinaryDocument[]> => {
  return request<ICloudinaryDocument[]>(
    getURLByEndpoint(API_PATH.documents.getDocumentsByTag, `?tag=${tag}`),
    HttpMethod.GET
  );
};

export const deleteDocumentByTagRequest = (
  tag: string
): Promise<CloudinaryResourceDeletedResponse[]> => {
  return request<CloudinaryResourceDeletedResponse[]>(
    getURLByEndpoint(API_PATH.documents.deleteDocumentByTag, `?tag=${tag}`),
    HttpMethod.DELETE
  );
};

export const getPropertyFileSelector = selectorFamily({
  key: 'GetPropertyFileSelector',
  get: (propertyUuid: string) => async () => {
    const tag = generateCloudinaryTag(PROPERTY_FILE_CLOUDINARY_TAG, propertyUuid);
    const propertyDocuments = await getDocumentsByTagRequest(String(tag));
    setRecoil(propertyFileState, propertyDocuments.length ? propertyDocuments[0] : null);
    return propertyDocuments;
  },
});

export const deleteCloudinaryFileByTagSelector = selector({
  key: 'DeleteCloudinaryFileByTag',
  get: ({ getCallback }) => {
    return getCallback(() => async (tag: string | undefined) => {
      if (!tag) return;
      return deleteDocumentByTagRequest(tag);
    });
  },
});

export const getClientLogoSelector = selectorFamily({
  key: 'GetPropertyFiles',
  get: (clientUuid: string) => () => {
    const tag = generateCloudinaryTag(CLIENT_LOGO_CLOUDINARY_TAG, clientUuid);
    return getDocumentsByTagRequest(String(tag));
  },
});

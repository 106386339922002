import { atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { PropertyRelatedEntities } from 'entityTypes/Property/Property';

export const { persistAtom: persistPropertyRelatedEntitiesAtom } = recoilPersist({
  key: 'PropertyRelatedEntities.relatedEntities',
});

export const propertyRelatedEntitiesState = atomFamily<PropertyRelatedEntities, string>({
  key: 'PropertyRelatedEntities.relatedEntities',
  default: undefined,
  effects: [persistPropertyRelatedEntitiesAtom],
});

import { IPreferencesFormValues } from 'components/pages/Preferences/form/PreferencesForm';
import { FailureCodes } from 'components/pages/SomethingWentWrongPage/constants';
import { API_PATH } from 'components/routing/constants/api';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { parsePermissionString } from 'components/routing/utils/permissions/permissionPathHelper';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { TBasePaths } from 'constants/translations';
import { ClientEntity } from 'entityTypes/Client';
import { settingUserModelWithDefaultValues, UserEntity } from 'entityTypes/User';
import { IUserClientPermission, UserClientEntity } from 'entityTypes/UserClient';
import { HttpMethod } from 'enums/httpMethodEnum';
import { i18n } from 'next-i18next';
import Router from 'next/router';
import { atom, selector, selectorFamily } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { adminUsersState } from 'recoil/atoms/adminAtoms';
import { userModelState } from 'recoil/atoms/userAtoms';
import { getURLByEndpoint } from 'recoil/selectors/SelectorsHelper';
import {
  AdditionalUserInfoResponse,
  AdminUser,
  CreateUserWithUserClientsDto,
  SaveUserPreferencesDto,
  UpdateUserWithUserClientsDto,
  UserTokenResponse,
} from 'types/user';

import { request } from '../../../pages/api/fetchService';
import { switchLanguage } from '../../components/routing/utils/languageUtils';
import { findUserClientByClientUuid } from './clientsSelectors';

export const USER_SUCCESS_MESSAGES = {
  CREATED: `${TBasePaths.PA_COMMON_MESSAGES}.userSuccessfullyCreated`,
  UPDATED: `${TBasePaths.PA_COMMON_MESSAGES}.userSuccessfullyUpdated`,
  DELETED: `${TBasePaths.PA_COMMON_MESSAGES}.userSuccessfullyDeleted`,
  PREFERENCES_UPDATED: `${TBasePaths.PA_COMMON_MESSAGES}.preferencesUpdatedSuccessfully`,
};

export function getAdditionalInfoForUsersRequest(): Promise<AdditionalUserInfoResponse> {
  return request<AdditionalUserInfoResponse>(
    getURLByEndpoint(API_PATH.user.getAdditionalInfoForUser),
    HttpMethod.GET
  );
}

export const getUserRequest = async (token: string): Promise<UserTokenResponse> => {
  return request<UserTokenResponse>(
    getURLByEndpoint(API_PATH.user.getUserInfo),
    HttpMethod.GET,
    undefined,
    token
  );
};

export function getAdminUsersRequest(): Promise<AdminUser[]> {
  return request<AdminUser[]>(
    getURLByEndpoint(API_PATH.user.getUsersWithUsersClients),
    HttpMethod.GET
  );
}

export const createUserWithUsersClientsRequest = async (
  dto: CreateUserWithUserClientsDto
): Promise<AdminUser> => {
  const res = await request<AdminUser>(
    getURLByEndpoint(API_PATH.user.createUserWithUserClients),
    HttpMethod.POST,
    dto
  );
  Snackbar.success(i18n!.t(USER_SUCCESS_MESSAGES.CREATED));
  return res;
};

export const updateUserWithUserClientsRequest = async (
  dto: UpdateUserWithUserClientsDto
): Promise<AdminUser> => {
  const res = await request<AdminUser>(
    getURLByEndpoint(API_PATH.user.updateUserWithUserClients),
    HttpMethod.PATCH,
    dto
  );
  Snackbar.success(i18n!.t(USER_SUCCESS_MESSAGES.UPDATED));
  return res;
};

export const deleteUserWithUserClientsRequest = async (userId: string): Promise<UserEntity> => {
  const res = await request<UserEntity>(
    getURLByEndpoint(API_PATH.user.deleteOne, userId),
    HttpMethod.DELETE
  );
  Snackbar.success(i18n!.t(USER_SUCCESS_MESSAGES.DELETED));
  return res;
};

export const saveUserPreferencesRequest = async (dto: SaveUserPreferencesDto) => {
  const res = await request(
    getURLByEndpoint(API_PATH.user.updateUserPreferences, '', 'update.json'),
    HttpMethod.PATCH,
    dto
  );
  Snackbar.success(i18n!.t(USER_SUCCESS_MESSAGES.PREFERENCES_UPDATED));
  return res;
};

export const updateUserActiveClientRequest = async (
  activeClientUuid: string
): Promise<ClientEntity | undefined> => {
  if (!activeClientUuid) return;
  return request<ClientEntity>(
    getURLByEndpoint(API_PATH.user.updateUserActiveClient, '', 'update.json'),
    HttpMethod.PATCH,
    {
      activeClientUuid,
    }
  );
};

export const createUserWithUserClientsSelector = selector({
  key: 'CreateUserWithUserClientsSelector',
  get: ({ getCallback, get }) => {
    const adminUsers = get(adminUsersState);

    return getCallback(() => async (dto: CreateUserWithUserClientsDto) => {
      const newUserWithClientsRes = await createUserWithUsersClientsRequest(dto);
      setRecoil(adminUsersState, [...adminUsers, newUserWithClientsRes]);
      return newUserWithClientsRes;
    });
  },
});

export const updateUserWithUserClientsSelector = selector({
  key: 'UpdateUserWithUserClientsSelector',
  get: ({ getCallback, get }) => {
    const user = get(userModelState);

    const updateCurrentUser = async (updatedAdminUser: AdminUser) => {
      const { userUuid, userClients } = updatedAdminUser;

      if (user.userUuid === userUuid) {
        const activeClientUuid = user.activeClientUuid || userClients[0].client.clientUuid;
        const userClient = await findUserClientByClientUuid(activeClientUuid);
        await updateUserActiveClientRequest(activeClientUuid);

        setRecoil(userModelState, (prevUser) => ({
          ...prevUser,
          ...updatedAdminUser,
          userClients,
          selectedUserClient: userClient,
          activeClientUuid,
        }));

        switchLanguage(userClient?.userLanguage);
      }
    };

    const updateAdminUsers = (updatedAdminUser: AdminUser) => {
      setRecoil(adminUsersState, (prevAdminUsers) =>
        prevAdminUsers.map((pau) =>
          pau.userUuid === updatedAdminUser.userUuid ? updatedAdminUser : pau
        )
      );
    };

    return getCallback(() => async (dto: UpdateUserWithUserClientsDto) => {
      const adminUser = await updateUserWithUserClientsRequest(dto);

      await updateCurrentUser(adminUser);

      updateAdminUsers(adminUser);

      return adminUser;
    });
  },
});

export const deleteUserWithUserClientsSelector = selector({
  key: 'DeleteUserWithUserClientsSelector',
  get: ({ getCallback, get }) => {
    const adminUsers = get(adminUsersState);
    const user = get(userModelState);

    return getCallback(() => async (currAdminUser: AdminUser) => {
      const deletedItem = await deleteUserWithUserClientsRequest(currAdminUser.userUuid);

      if (user.userUuid === currAdminUser.userUuid) {
        await Router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_NOT_EXIST));
        setRecoil(userModelState, settingUserModelWithDefaultValues());
      }

      const adminUsersWithoutDeleted = adminUsers.filter(
        (i: AdminUser) => i.userUuid !== deletedItem.userUuid
      );
      setRecoil(adminUsersState, adminUsersWithoutDeleted);
    });
  },
});

export const saveUserPreferencesSelector = selector({
  key: 'SaveUserPreferencesSelector',
  get: ({ getCallback, get }) => {
    const user = get(userModelState);
    const updateCurrentUser = (
      newUser: Partial<UserEntity>,
      newUserClient: Partial<UserClientEntity>
    ) => {
      // selectedUserClient
      const selectedUserClient = {
        ...user.selectedUserClient!,
        ...newUserClient,
      };

      // selectedUserClient.currency
      const matchingCurrency = selectedUserClient?.client.clientCurrencies.find(
        (uc) => uc.currencyCode === selectedUserClient.preferredCurrencyCode
      )?.currency;
      if (matchingCurrency) {
        selectedUserClient.currency = matchingCurrency;
      }

      // userClients
      const userClients = user.userClients.map((uc) =>
        uc.userClientUuid === user.selectedUserClient?.userClientUuid
          ? { ...uc, ...newUserClient, currency: matchingCurrency! }
          : uc
      );

      setRecoil(userModelState, {
        ...user,
        ...newUser,
        userClients,
        selectedUserClient,
      });
    };

    return getCallback(() => async (data: IPreferencesFormValues) => {
      const {
        userInitial,
        firstName,
        lastName,
        userName,
        email,
        oktaUserId,
        preferredUnitMeas,
        preferredCurrencyCode,
        formatDate,
        formatNumber,
        timezone,
        userLanguage,
      } = data;

      const newUser = {
        userInitial,
        firstName,
        lastName,
        userName,
        email,
        oktaUserId,
      };

      const newUserClient = {
        preferredUnitMeas,
        preferredCurrencyCode,
        formatDate,
        formatNumber,
        userLanguage,
        timezone,
      };

      await saveUserPreferencesRequest({
        user: newUser,
        userClient: {
          ...newUserClient,
          userClientUuid: user.selectedUserClient!.userClientUuid,
        },
      });

      updateCurrentUser(newUser, newUserClient);
    });
  },
});

export const additionalUserInfoState = atom({
  key: 'AdditionalUserInfoState',
  default: selector({
    key: 'AdditionalUserInfoStateResponseStateLoader',
    get: (): Promise<AdditionalUserInfoResponse> => {
      return getAdditionalInfoForUsersRequest();
    },
  }),
});
export const getAdminUsersSelector = selector({
  key: 'GetAdminUsersSelector',
  get: async (): Promise<AdminUser[]> => {
    const res = await getAdminUsersRequest();
    setRecoil(adminUsersState, res);
    return res;
  },
});

export const getUserClientPermissions = selectorFamily<IUserClientPermission[], string>({
  key: 'User.clientPermissions',
  get:
    (clientUuid) =>
    ({ get }) => {
      const userModel = get(userModelState);
      const userClientPermissions = parsePermissionString(
        clientUuid,
        userModel.selectedUserClient?.primusPermission
      );
      return userClientPermissions;
    },
});
